// Avoid `console` errors in browsers that lack a console.
(function () {
    var method;
    var noop = function () {
    };
    var methods = [
        'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
        'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
        'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
        'timeline', 'timelineEnd', 'timeStamp', 'trace', 'warn'
    ];
    var length = methods.length;
    var console = (window.console = window.console || {});

    while (length--) {
        method = methods[length];

        // Only stub undefined methods.
        if (!console[method]) {
            console[method] = noop;
        }
    }
}());

$.fn.isInViewport = function () {
    var elementTop = $(this).offset() ? $(this).offset().top : null;
    var elementBottom = elementTop + $(this).outerHeight();

    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
};

$("document").ready(function () {

    $(window).trigger('scroll');

    var main_mesage = anime.timeline();
    main_mesage
        .add({
            targets: '.message__inner h1',
            top: 0,
            opacity: 1,
            duration: 1000,
            delay: 500,
            easing: 'easeInOutQuad'
        })
        .add({
            targets: '.message__inner h2',
            top: 0,
            opacity: 1,
            duration: 500,
            easing: 'easeInOutQuad',
            offset: "-=200"
        })
        .add({
            targets: '.message__btn',
            opacity: 1,
            duration: 700,
            easing: 'easeInOutQuad',
            offset: "+=500"
        });

    /*$(".btn--gradient").hover(function () {
        console.log("work");
        var btn_gradient = anime({
            targets: '.message__btn',
            background: [
                {value: "linear-gradient(to right, rgba(0, 219, 175, 1) 0%, rgba(5, 161, 240, 1) 100%)"},
                {value: "linear-gradient(to bottom right, rgba(0, 219, 175, 1) 0%, rgba(5, 161, 240, 1) 100%)"},
                {value: "linear-gradient(to bottom, rgba(0, 219, 175, 1) 0%, rgba(5, 161, 240, 1) 100%)"},
                {value: "linear-gradient(to bottom left, rgba(0, 219, 175, 1) 0%, rgba(5, 161, 240, 1) 100%)"},
                {value: "linear-gradient(to left, rgba(0, 219, 175, 1) 0%, rgba(5, 161, 240, 1) 100%)"},
            ],
            duration: 300,
            easing: 'linear'
        });
    }, function () {
        var btn_gradient = anime({
            targets: '.message__btn',
            background: [
                {value: "linear-gradient(to left, rgba(0, 219, 175, 1) 0%, rgba(5, 161, 240, 1) 100%)"},
                {value: "linear-gradient(to bottom left, rgba(0, 219, 175, 1) 0%, rgba(5, 161, 240, 1) 100%)"},
                {value: "linear-gradient(to bottom, rgba(0, 219, 175, 1) 0%, rgba(5, 161, 240, 1) 100%)"},
                {value: "linear-gradient(to bottom right, rgba(0, 219, 175, 1) 0%, rgba(5, 161, 240, 1) 100%)"},
                {value: "linear-gradient(to right, rgba(0, 219, 175, 1) 0%, rgba(5, 161, 240, 1) 100%)"},
            ],
            duration: 300,
            easing: 'linear'
        });
    });*/


    $('.first-screen__arrow a').on('click', function () {
        var target = $(this).attr('data-target'); //should be css selector (e.g. data-target=".next-block")
        $('html, body').animate({
            scrollTop: $(target).offset().top
        }, 1000);

        showAboutSteps();

        return false;
    });

    var scroll_counter = 0;
    $(window).on('resize scroll', checkView);

    function checkView() {
        if ($(".about").isInViewport()) {
            showAboutSteps();
        }
        if ($(".story__row").isInViewport()) {
            showStoryRow();
        }
        if ($(".our-team").isInViewport()) {
            ourTeam();
        }
        /*if ($(".join-us").isInViewport()) {
            joinUs();
        }*/
    }

    function joinUs() {
        //$(window).off('resize scroll');
        if ($(".join-us").hasClass('animated')) {
            return;
        }
        var join_us = anime.timeline();
        join_us
        /*.add({
            targets: ".join-us__text span",
            width: [{value: '100px'}, {value: '300px'}, {value: '200px'}, {value: '200px'}, {value: '100px'}],
            left: [{value: '-100px'}, {value: '900px'}],
            duration: 3500,
            easing: "linear"
        })*/
            .add({
                targets: '.join-us .btn',
                scale: [{value: 1.2}, {value: 1}],
                duration: 500,
                easing: [.91, -0.54, .29, 1.56],
                complete: function (anim) {
                    join_us = null;
                }
            });
        // setTimeout(function () {
        //     $(window).on('resize scroll', checkView);
        // }, 1500)
        $(".join-us").addClass('animated');
    }

    function ourTeam() {
        //$(window).off('resize scroll');
        if ($(".our-team").hasClass('animated')) {
            return;
        }
        var team = anime.timeline();
        team
            .add({
                targets: ".about__title h3",
                top: 0,
                duration: 500,
                delay: 500,
                easing: 'linear'
            })
            .add({
                targets: '.about__desc p',
                top: 0,
                opacity: 1,
                duration: 500,
                offset: "-=200",
                easing: 'linear'
            })
            .add({
                targets: [".our-team .employee:nth-child(1)", ".our-team .employee:nth-child(4)"],
                opacity: 1,
                left: 0,
                duration: 500,
                offset: "-=500",
                easing: 'linear'
            })
            .add({
                targets: [".our-team .employee:nth-child(2)", ".our-team .employee:nth-child(3)"],
                opacity: 1,
                left: 0,
                duration: 500,
                offset: "-=200",
                easing: 'linear'
            });
        // setTimeout(function () {
        //     $(window).on('resize scroll', checkView);
        // }, 1500)
        $(".our-team").addClass('animated');
    }

    function showStoryRow() {
        //$(window).off('resize scroll');
        if ($(".story").hasClass('animated')) {
            return;
        }
        var story = anime.timeline();
        story
            .add({
                targets: ".story__row--left .story__image",
                opacity: 1,
                duration: 500,
                easing: 'linear'
            })
            .add({
                targets: ".story__row--left .story__title h3",
                top: 0,
                duration: 500,
                offset: "-=300",
                easing: 'linear'
            })
            .add({
                targets: '.story__row--left .story__text p',
                top: 0,
                opacity: 1,
                duration: 500,
                offset: "-=500",
                easing: 'linear'
            })
            .add({
                targets: ".story__row--right .story__image",
                opacity: 1,
                duration: 500,
                offset: "+=500",
                easing: 'linear'
            })
            .add({
                targets: ".story__row--right .story__title h3",
                top: 0,
                duration: 500,
                offset: "-=500",
                easing: 'linear'
            })
            .add({
                targets: '.story__row--right .story__text p',
                top: 0,
                opacity: 1,
                duration: 500,
                offset: "-=500",
                easing: 'linear'
            });
        /*setTimeout(function () {
            $(window).on('resize scroll', checkView);
        }, 3000)*/
        $(".story").addClass('animated');
    }

    function showAboutSteps() {
        //$(window).off('resize scroll');
        if ($(".about").hasClass('animated')) {
            return;
        }
        var about_steps = anime.timeline();
        about_steps
            .add({
                targets: ".about__title h3",
                top: 0,
                duration: 500,
                easing: 'linear'
            })
            .add({
                targets: '.about__desc p',
                top: 0,
                opacity: 1,
                duration: 500,
                offset: "-=500",
                easing: 'linear'
            })
            .add({
                targets: '.about__step:nth-child(1)',
                scale: 1,
                opacity: 1,
                duration: 500,
                easing: 'easeOutQuad'
            })
            .add({
                targets: '.about__step:nth-child(2)',
                scale: 1,
                opacity: 1,
                duration: 500,
                easing: 'easeOutQuad',
                offset: "-=100"
            })
            .add({
                targets: '.about__step:nth-child(3)',
                scale: 1,
                opacity: 1,
                duration: 500,
                easing: 'easeOutQuad',
                offset: "-=100"
            })
            .add({
                targets: '.about__step:nth-child(6)',
                scale: 1,
                opacity: 1,
                duration: 500,
                easing: 'easeOutQuad',
                offset: "-=100"
            })
            .add({
                targets: '.about__step:nth-child(5)',
                scale: 1,
                opacity: 1,
                duration: 500,
                easing: 'easeOutQuad',
                offset: "-=100"
            })
            .add({
                targets: '.about__step:nth-child(4)',
                scale: 1,
                opacity: 1,
                duration: 500,
                easing: 'easeOutQuad',
                offset: "-=100"
            });
        $(".about").addClass('animated');
        /*setTimeout(function () {
            $(window).on('resize scroll', checkView);

        }, 2000)*/
    }


    var step_curr_id;
    $(".element, .partners__link").hover(function () {
        step_curr_id = $(this).find("filter").attr("id");
        $(this).find("path").attr("filter", '').attr("fill", "#ffffff");
    }, function () {
        $(this).find("path").attr("filter", "url(#" + step_curr_id + ")").attr("fill", "#535d70");
    });

    $(".coindesk").hover(function () {
        $(this).find("#coindesk_normal").hide();
        $(this).find("#coindesk_white").show()
    }, function () {
        $(this).find("#coindesk_white").hide();
        $(this).find("#coindesk_normal").show()
    });

    $(".nav-list .hamburger").click(function () {
        $(".nav-list__wrapper").toggleClass("nav-list__wrapper--active");
    });


    $('button[data-toggle="collapse"]').click(function (e) {
// Grab current anchor value
        var currentAttrValue = $(this).attr('data-target');
        if ($(this).hasClass('open')) {
            $(this).removeClass('open');
            $("#" + currentAttrValue).slideUp(300).removeClass('open');
        } else {
            $('button[data-toggle="collapse"]').removeClass('open');
            $(".collapse").slideUp(300).removeClass('open');
// Add active class to section title
            $(this).addClass('open');
// Open up the hidden content panel
            $("#" + currentAttrValue).slideDown(300).addClass('open');
        }
        if ($(this).hasClass("comments__button")) {
            $(this).parents(".news__meta").toggleClass("open");
        }
        e.preventDefault();
    });

    $('.news-list').masonry({
        // options...
        itemSelector: '.news'
    });

    var about_steps = anime.timeline();
    about_steps

        .add({
            targets: ".news-list",
            opacity: 1,
            duration: 500,
            easing: 'easeOutQuad',
            offset: "+=200"
        });

    // Select all links with hashes
    $('a[href*="#"]')
    // Remove links that don't actually link to anything
        .not('[href="#"]')
        .not('[href="#0"]')
        .click(function(event) {
            // On-page links
            if (
                location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
                &&
                location.hostname == this.hostname
            ) {
                // Figure out element to scroll to
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                // Does a scroll target exist?
                if (target.length) {
                    // Only prevent default if animation is actually gonna happen
                    event.preventDefault();
                    $('html, body').animate({
                        scrollTop: target.offset().top
                    }, 1000, function() {
                        // Callback after animation
                        // Must change focus!
                        var $target = $(target);
                        $target.focus();
                        if ($target.is(":focus")) { // Checking if the target was focused
                            return false;
                        } else {
                            $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
                            $target.focus(); // Set focus again
                        };
                    });
                }
            }
        });

});